import React from "react";
import { withNamespaces } from "react-i18next";
import styles from "../transportInformation/transportInformation.module.scss";
import Dropdown from "../../inputs/dropdown/dropdown";
import * as actions from "../../../../action";
import { connect } from "react-redux";
import { getLeadField } from "../../../../utils/utils";
import KindOfStorageExtra from "./kindOfStorage/kindOfStorageExtra";
import _ from "lodash";

class KindOfStorage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
  }

  componentDidMount() {
    this.props.setFormValidity(false, this.props.id);
    this.props.componentLoaded();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.initialInfo && props.initialInfo.storageTypes) {
      const value = getLeadField(props);
      if (value && value.type) {
        return { selectedOption: value };
      }
      return null;
    }
    return null;
  }

  isSelectedStep = id => {
    return this.props.selectedStep === id;
  };

  onChange = selectedIndex => {
    const optionsNeedExtraStep = [
      "loose",
      "container",
      "lattice box",
      "barrel",
      "bigbag",
      "pallet",
      "pallet_cage",
      "other",
    ];
    const selectedOption = this.sortedStorageTypes()[selectedIndex];
    this.setState({ selectedOption });

    if (optionsNeedExtraStep.indexOf(selectedOption.value) !== -1) {
      this.props.setFormValidity(false, this.props.id);
    } else {
      this.props.setFormValidity(null, this.props.id, true);
    }
  };

  sortedStorageTypes = () => {
    const unsortedStorageTypes = this.props.storageTypes;
    if (!unsortedStorageTypes) {
      return [];
    }

    const { t } = this.props;
    let sortedStorageTypes = [];
    let otherElement = null;
    unsortedStorageTypes.map(type => {
      if (type.value === "other") {
        otherElement = {
          label: t(type.label),
          value: type.value,
        };
      } else if (type.parent === null) {
        sortedStorageTypes.push({
          label: t(type.label),
          value: type.value,
        });
      }
    });

    let returnValue = _.orderBy(sortedStorageTypes, ["label"], ["asc"]);
    returnValue.push(otherElement);
    return returnValue;
  };

  render() {
    const { t } = this.props;

    const isSelectedStep = this.isSelectedStep(this.props.id);
    let containerClass = "";
    if (isSelectedStep) {
      containerClass = " active";
    }

    return (
      <div
        className={`${styles.clickHere} ${styles.section}` + containerClass}
        id={this.props.id}
        onClick={() => this.props.selectStep(this.props.id)}
      >
        <span className={styles.sectionDescription}>
          {t("How is the material currently stored?")}
        </span>
        <Dropdown
          options={this.sortedStorageTypes()}
          propagateToParent={this.onChange}
          field="storageType.type"
          cartIndex={this.props.cartIndex}
        />
        <KindOfStorageExtra
          selectedOption={this.state.selectedOption}
          cartIndex={this.props.cartIndex}
          isSelectedStep={isSelectedStep}
          setFormValidity={this.props.setFormValidity}
          id={this.props.id}
        />
      </div>
    );
  }
}

function mapStateToProps({ leadForm }) {
  return {
    initialInfo: leadForm.initialInfo,
    lead: leadForm.lead,
    magicLinkToken: leadForm.magicLinkToken,
    storageTypes: leadForm.initialInfo.storageTypes,
    selectedStep: leadForm.selectedStep,
  };
}

export default connect(
  mapStateToProps,
  actions
)(withNamespaces()(KindOfStorage));
