import React from "react";
import { withNamespaces } from "react-i18next";
import * as actions from "../../../../../action";
import { connect } from "react-redux";
import LooseStorage from "./looseStorage";
import ContainerStorage from "./containerStorage";
import TextStorage from "./textStorage";
import MultipleStepStorage from "./multipleStepStorage";

class KindOfStorageExtra extends React.Component {
  getComponent = () => {
    const { selectedOption } = this.props;
    if (selectedOption) {
      switch (selectedOption.type) {
        case "loose":
          return (
            <LooseStorage
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              field="storageType.text"
              setFormValidity={this.props.setFormValidity}
              id={this.props.id}
            />
          );
        case "container":
          return (
            <ContainerStorage
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              field="storageType.secondaryType"
              setFormValidity={this.props.setFormValidity}
              id={this.props.id}
            />
          );
        case "lattice box":
          return (
            <TextStorage
              title="Please enter the number of gitter boxes"
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              field="storageType.text"
              setFormValidity={this.props.setFormValidity}
              id={this.props.id}
            />
          );
        case "barrel":
          return (
            <TextStorage
              title="Please enter the number of Fass"
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              field="storageType.text"
              setFormValidity={this.props.setFormValidity}
              id={this.props.id}
            />
          );
        case "pallet":
        case "bigbag":
        case "pallet_cage":
          return (
            <MultipleStepStorage
              title="Please enter the number of Palletes"
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              field="storageType.secondaryType"
              setFormValidity={this.props.setFormValidity}
              id={this.props.id}
              parentType={selectedOption.type}
            />
          );
        case "other":
          return (
            <TextStorage
              title="How material is stored (for understanding what kind of transport is needed)"
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              field="storageType.text"
              setFormValidity={this.props.setFormValidity}
              id={this.props.id}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  render() {
    return <>{this.getComponent()}</>;
  }
}

function mapStateToProps({ leadForm }) {
  return {
    initialInfo: leadForm.initialInfo,
    lead: leadForm.lead,
    magicLinkToken: leadForm.magicLinkToken,
    storageTypes: leadForm.initialInfo.storageTypes,
    selectedStep: leadForm.selectedStep,
  };
}

export default connect(
  mapStateToProps,
  actions
)(withNamespaces()(KindOfStorageExtra));
