import React, { useEffect, useContext } from "react";
import { withNamespaces } from "react-i18next";
import styles from "./redirect.module.scss";
import logo from "../../../images/schrottLoader.gif";
import _ from "lodash";
import * as actions from "../../../action";
import { connect } from "react-redux";
import DomainContext from "../../../context/DomainContext";
import { trackRedirection } from "../../utils/eventTracker";

const Redirect = props => {
  useEffect(() => {
    setTimeout(() => {
      const cartItem = props.lead.cart[0];
      // This value should be procided in Kg and now is in Ton
      const quantity = parseInt(cartItem.quantity * 1000);
      const productId = cartItem.productID;
      // TODO this url should be changed depending on webshop
      var url = `https://www.schrott24.de/co/cart/${productId}?&quantity=${quantity}`;
      if (domain === "ch") {
        //Redirect to sorec and also add the event in analytic
        url = `https://shop.sorec.ch/co/cart/g/u5YiZt7yJRH4teEmZ/${productId}?quantity=${quantity}`;
        trackRedirection(productId, props.products, quantity);
      }

      window.location = url;
    }, 5000);
  });

  const { t } = props;
  const domain = useContext(DomainContext);
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.separator}></div>
        <div className={styles.innerContainer}>
          <img src={logo} alt="Loader logo" />
          <span className={styles.text}>
            {t(
              " We are scanning our database to provide you with the best prices & transport options"
            )}
          </span>
        </div>
        <div className={styles.separator}></div>
      </div>
    </div>
  );
};

function mapStateToProps({ leadForm }) {
  return { lead: leadForm.lead, products: leadForm.initialInfo.products };
}

export default connect(mapStateToProps, actions)(withNamespaces()(Redirect));
