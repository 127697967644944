import React from "react";
import { withNamespaces } from "react-i18next";
import Dropdown from "../../../inputs/dropdown/dropdown";
import * as actions from "../../../../../action";
import { connect } from "react-redux";
import styles from "../materialInformation.module.scss";
import inputStyles from "../../../inputs/input/input.module.scss";
import Input from "../../../inputs/input/input";
import _ from "lodash";

class MultipleStepStorage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: null,
      number: null,
      error: null,
    };
  }

  componentDidMount() {
    if (
      this.props.lead &&
      this.props.lead.cart &&
      this.props.lead.cart.length > this.props.cartIndex
    ) {
      const cartItem = this.props.lead.cart[this.props.cartIndex];

      if (cartItem) {
        const selectedValue = cartItem.storageType.secondaryType
          ? cartItem.storageType.secondaryType
          : null;
        const number = cartItem.storageType.text
          ? cartItem.storageType.text
          : null;
        this.setState({
          selectedValue,
          number,
        });
      }
    }
  }

  onChange = async index => {
    const selectedValue = this.getAvailableOptions()[index].value;

    await this.setState({ selectedValue });
  };

  onChangeInput = async value => {
    await this.setState({ number: value });
    let obj = null;
    if (!value || value === "") {
      obj = { error: "Number required" };
    }
    this.setState({ error: obj ? obj.error : null });
    this.props.setFormValidity(obj, this.props.id);
  };

  getClassOption = option => {
    if (option === this.state.selectedOption) {
      return " " + styles.selected;
    }
    return "";
  };

  isAvailableStep = step => {
    switch (step) {
      case 1:
        return this.state.selectedValue !== null;
      default:
        return false;
    }
  };

  getAvailableOptions = () => {
    const storageTypes = this.props.initialInfo.storageTypes;
    let types = [];
    storageTypes.map(type => {
      if (type.parent === this.props.parentType) {
        types.push(type);
      }
    });
    return types;
  };

  getSubtypeTitle = () => {
    const { t, parentType } = this.props;
    if (parentType === "bigbag") {
      return t("Bigbag type");
    }
    if (parentType === "pallet") {
      return t("Pallet type");
    }
    if (parentType === "pallet_cage") {
      return t("Pallet cage type");
    }
    return t("Select subtype");
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.animatedContainerStorage}>
        <span
          className={
            styles.sectionDescription + " " + styles.storageOptionTitle
          }
        >
          {this.getSubtypeTitle()}
        </span>

        <div className={styles.animatedContainerStorageStep2}>
          <Dropdown
            options={this.getAvailableOptions()}
            propagateToParent={this.onChange}
            cartIndex={this.props.cartIndex}
            selectedValue={this.state.selectedValue}
            placeholder={this.getSubtypeTitle()}
            field={"storageType.secondaryType"}
          />
        </div>

        {this.isAvailableStep(1) ? (
          <div className={styles.animatedContainerStorageStep3}>
            <span
              className={
                styles.sectionDescription + " " + styles.storageOptionTitle
              }
            >
              {t("Number")}
            </span>
            <Input
              id="multipleStorage"
              type="number"
              required={true}
              avoidValidate={true}
              callBackValidation={this.isReadyToContinue}
              placeholder={t("Number")}
              cartIndex={this.props.cartIndex}
              isSelectedStep={this.props.isSelectedStep}
              propagateToParent={this.onChangeInput}
              value={this.state.number}
              field={"storageType.text"}
            />
            <div className={styles.loseErrorContainer}>
              <span className={inputStyles.error}>{t(this.state.error)}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps({ leadForm }) {
  return {
    initialInfo: leadForm.initialInfo,
    lead: leadForm.lead,
    magicLinkToken: leadForm.magicLinkToken,
    storageTypes: leadForm.initialInfo.storageTypes,
    selectedStep: leadForm.selectedStep,
  };
}

export default connect(
  mapStateToProps,
  actions
)(withNamespaces()(MultipleStepStorage));
