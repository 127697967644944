import React, { useState, useEffect } from "react";
import styles from "./finish.module.scss";
import { withNamespaces } from "react-i18next";
import logo from "../../../images/logoThankYou.png";
const Finish = ({ t, hideIframe }) => {
  const [showAlt, setShowAlt] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    setTimeout(() =>
      setIframeSrc(() => "https://www.getfeedback.com/e/oUnkVYdp", 2000)
    );
  }, []);

  const onLoad = () => {
    setShowAlt(true);
  };
  return (
    <>
      {showAlt && hideIframe !== true ? (
        <div className={styles.altContent}>
          <h1>
            {t("Thank you for your request.")}
            <br />
            {t("We will contact you shortly")}
          </h1>
        </div>
      ) : (
        ""
      )}

      {hideIframe == true ? (
        <div className={styles.altThankYou}>
          <div className={styles.altWrapper}>
            <div className={styles.altContainer}>
              {" "}
              <img src={logo} alt="Loader logo" />
              <h1>
                {t("Thank you for submitting")}
<br/>
                {t("the additional information")}

              </h1>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {hideIframe !== true ? (
        <iframe
          className={styles.thankYouIframe}
          src={iframeSrc}
          onLoad={onLoad}
        ></iframe>
      ) : (
        ""
      )}
    </>
  );
};

export default withNamespaces()(Finish);
